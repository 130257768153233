import axios from 'axios'

let tokenName = document.head.querySelector('meta[name="csrf-token-name"]');
let tokenValue = document.head.querySelector('meta[name="csrf-token-value"]');
let apiURL = document.head.querySelector('meta[name="api-base-url"]');
let graphQLURL = document.head.querySelector('meta[name="graphql-base-url"]');

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-Token'] = tokenValue.content;


export default {
    API_URL: apiURL.content,
    GRAPHQL_URL: graphQLURL.content,
    CSRF_TOKEN_NAME: tokenName.content,
    CSRF_TOKEN_VALUE: tokenValue.content,
};