import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import config from '../api/config'
import ProductPageHero from "../../vue/ProductPageHero/ProductPageHero.vue";
import {t} from "../utils/filters";
import HeroProduct from "../../vue/ProductPageHero/HeroProduct.vue";
import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);
Vue.filter('t', t);
const router = new VueRouter({
    base: window.basePath,
    mode: "history",
    routes: [
        { path: '/', component: HeroProduct, },
        { path: '/:productSlug', component: HeroProduct, name: 'product' },
        { path: '/:productSlug/color/:colorSlug', component: HeroProduct, name: 'productColor' },
        { path: '/:productSlug/stain/:colorSlug', component: HeroProduct, name: 'productStain' },
        { path: '/:productSlug/primed', component: HeroProduct, name: 'productPrimed' },
        { path: '*', component: HeroProduct},
    ]
});

const app = new Vue({
    router,
    components: {
        'product-page-hero': ProductPageHero,
    },
    data: {
        productInfo: window.productInfo
    },
}).$mount('#product-page-hero');



// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
